const BlockFore = ({ data }) => {

  const gridItemStyle = {
    backgroundImage: `url(${data?.section_image})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'relative'
  }

  return (
    <div className="max-h-[720px] h-[55vh] md:h-[60vh] w-full">
      <div className="bg-fixed bg-bottom h-full max-h-[400px] flex justify-end bg-bottom md:bg-center" style={gridItemStyle}>
      </div>
    </div>
  )
}

export default BlockFore