import React from "react";
import ThemeToggleButton from "./ThmeButton";
import { Link } from "react-router-dom";
import Instagram from "../images/instagram.svg";

const menu = [
  {title: 'EXHIBITION', href: 'exhibition'},
  {title: 'ABOUT', href: 'about'},
  {title: 'WORKS', href: 'works'},
  {title: 'GALLERY', href: 'gallery'},
  {title: 'CONTACTS', href: 'contacts'},
]

const MobileMenu = ({ setShowBurger }) => {
  return (
    <>
      <div className="flex flex-col h-full justify-between">
        <div>
          <div className="mb-4 w-20 md:w-24">
            <ThemeToggleButton />
          </div>
          <div className='lg:flex gap-x-[25px] space-y-[25px]'>
            {menu.map((item, index) => {
              return (
                <div key={index}>
                  <Link onClick={() => setShowBurger(false)} to={item.href}>
                    <div>
                      <p className="text-darkBg dark:text-lightBg">{item.title}</p>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex text-[#E1C17B] flex-row justify-between mt-8">
          <div className="flex flex-col">
            <Link target="_blank" to={`tel:+37477083388`}>(+374) 77 083388</Link>
            <Link target="_blank" to={`mailto:Sargis.bab@gmail.com`}>Sargis.bab@gmail.com</Link>
          </div>
          <Link to='https://www.instagram.com/sargis.babayann/'>
            <img alt="svg" src={Instagram} />
          </Link>
        </div>
      </div>
    </>
  )
}

export default MobileMenu
