const BlockOne = ({ data }) => {

  const gridItemStyle = {
    backgroundImage: `url(${data?.general_image})`,
    backgroundRepeat: 'no-repeat',
    position: 'relative'
  }

  return (
    <div className="px-5 lg:px-56 xl:px-72 pb-0 md:pb-12 pt-6 h-[35vh] sm:h-[80vh] md:h-[100vh]">
      <div className="hidden lg:flex flex-col justify-center items-center mb-4">
        <h1 className="text-darkBg dark:text-lightBg text-xl md:text-7xl font-bold font-custom">{data?.full_name}</h1>
        <p className="text-subTitle text-base md:text-3xl font-semibold">{data?.profession}</p>
      </div>
      <div className="w-full h-full max-h-[400px] md:max-h-[720px] flex justify-center bg-contain md:bg-cover mb-5" style={gridItemStyle}>
      </div>
      <p className="text-subTitle text-base md:text-2xl font-semibold">{data?.description}</p>
      <p className="text-darkBg dark:text-lightBg text-base md:text-xl font-semibold mt-2">{data?.sub_description}</p>
    </div>
  )
}

export default BlockOne