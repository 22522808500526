import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { baseApiUrl } from "../service/base-url";

const Exhibition = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${baseApiUrl}/exhibitions/${id}`, {
          headers: { 'Content-Type': 'application/json' }
        })
        const dataRes = response.data
        if (dataRes) {
          setProduct(dataRes.data)
        }
      } catch (error) {
        toast.error('Please try again.')
        console.error('Error:', error)
      }
    }

    fetchProduct();
  }, [id]);

  if (!product) return <p>Loading...</p>;

  return (
    <div className="product-page mt-16 px-5 md:px-20">
      <h1 className="text-2xl ext-darkBg dark:text-lightBg font-custom tracking-wide">{product.name}</h1>
      <p className="text-2xl ext-darkBg dark:text-lightBg font-custom tracking-wide">Created Date: {product.created_date}</p>
      <div className="mt-24 flex flex-wrap justify-center gap-x-10">
        {product.gallery.map((image, index) => (
          <div key={index}>
            <img
              src={image.url}
              alt="img"
              className="m-auto h-full max-h-[350px] min-h-[350px] cursor-pointer mt-14 min-w-[320px] md:min-w-[400px] max-w-[320px] md:max-w-[400px]"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Exhibition;
