const GalleryComponents = ({ gallery }) =>{
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-10 space-y-6 md:space-y-0">
      {gallery && gallery.map((image, index) => {
        return (
          <div key={index} className="">
            <img className="w-full max-h-[350px]" src={image.image} alt="png" />
          </div>
        )
      })}
    </div>
  )
}

export default GalleryComponents;

