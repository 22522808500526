import { Link } from "react-router-dom";

const ShowProducts = ({ works }) => {

  return (
    <div className="flex justify-center">
      <div className="mt-12 justify-center items-center flex-wrap flex-col lg:flex-row flex gap-x-3">
        {works.map((item,index) => {
          return (
            <Link className="mt-8" key={index} to={`/works/${item.id}`}>
              <div className="flex flex-col justify-between w-[100%] lg:w-[100%]">
                <div className="w-full max-w-[330px] 2xl:max-w-[428px] max-h-full md:max-h-[300px] lg:min-h-[428px]">
                    <img className="h-full min-h-[300px] md:min-h-[428px] object-contain" alt="png" src={item.image}/>
                </div>
                <div className="mt-3">
                  <p className="text-darkBg dark:text-lightBg font-customFont font-light text-[22px]">{item.name}</p>
                  <p className="text-darkBg dark:text-lightBg font-customFont font-light text-lg">{item.sizes}</p>
                  <p className="text-darkBg dark:text-lightBg font-customFont font-light text-lg">{item.materials}</p>
                  <p className="text-darkBg dark:text-lightBg font-customFont font-light text-[22px]">{item.created_date}</p>
                </div>
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}
export default ShowProducts;
