import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { baseApiUrl } from "../service/base-url";
import Swiper from "../components/Swiper";

const Work = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${baseApiUrl}/works/${id}`, {
          headers: { 'Content-Type': 'application/json' }
        })
        const dataRes = response.data
        if (dataRes) {
          setProduct(dataRes.data)
        }
      } catch (error) {
        toast.error('Please try again.')
        console.error('Error:', error)
      }
    }

    fetchProduct();
  }, [id]);


  if (!product) return <p>Loading...</p>;

  return (
    <div className="product-page">
      <Swiper works={product}  />
    </div>
  );
};

export default Work;
