import { getDynamicData } from "../service/base-url";
import React, { useEffect, useState } from "react";
import GalleryComponents from "../components/GalleryComponents";

const Gallery  = () => {
  const [galleryData, setGalleryData] = useState()

  useEffect(() => {
    getDynamicData('gallery', setGalleryData, null)
  }, [])

  return (
    <div className="mt-6 px-5 md:px-20">
      <h1 className="text-4xl ext-darkBg dark:text-lightBg font-custom tracking-wide text-center mb-10">Gallery</h1>
      <GalleryComponents
       gallery={galleryData} />
    </div>
  )
}

export default Gallery
