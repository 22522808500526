import { Link } from "react-router-dom";
import Arrow from '../images/arrow-right.svg';

const GoTo = ({ title, to }) => {
  return (
    <div className="flex justify-center md:justify-end mt-7 md:mt-14 pr-0 md:pr-16">
      <div className="flex gap-x-4">
        <Link className="text-darkBg dark:text-lightBg" to={to}>
          {title}
        </Link>
        <img alt="png" src={Arrow} />
      </div>
    </div>
  )
}

export default GoTo;
