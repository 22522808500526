import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Mousewheel, Keyboard, EffectFade, Thumbs } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'
import 'swiper/css/thumbs'

const SwiperAds = ({ works = [] }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  return (
    <div className="shadow-myShadow px-4 md:px-36 py-[15px] rounded-lg">
      <Swiper
        style={{
          '--swiper-navigation-color': '#64748b',
          '--swiper-pagination-color': '#64748b'
        }}
        loop
        effect='fade'
        navigation={true}
        fadeEffect={{ crossFade: true }}
        pagination={{
          dynamicBullets: true,
          clickable: true
        }}
        thumbs={{ swiper: thumbsSwiper }}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard, EffectFade, Thumbs]}
        className="mySwiper"
      >
        {works.gallery.map((image, index) => (
          <SwiperSlide className="rounded-xl" key={index}>
            <div style={{backgroundImage: `url(${image?.url})`,}}
              className="h-full min-h-[450px] cursor-pointer bg-no-repeat bg-center relative rounded-xl"
            >
              <div className="absolute bg-[#8080808C] left-0 text-darkBg dark:text-lightBg font-custom px-4 py-4 rounded-xl top-8">
                <h1 className="text-4xl">{works.name}</h1>
                <div className="text-xl mt-8 space-y-3">
                  <p>Sizes: {works.sizes}</p>
                  <p>Materials: {works.materials}</p>
                  <p>Created Date: {works.created_date}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="hidden lg:block">
        <Swiper
          style={{
            marginTop: '11px'
          }}
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={6}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[EffectFade, Navigation, Thumbs]}
        >
          {works.gallery.map((image, index) => (
            <SwiperSlide key={index} className="rounded-sm cursor-pointer">
              <img src={image.url} alt="png" className="object-cover rounded-xl w-72 h-36 cursor-pointer" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default SwiperAds
