import axios from 'axios'
import toast from 'react-hot-toast'

const isLocal = process.env.NODE_ENV === 'local'
export const baseApiUrl = isLocal
  ? 'https://admin.sargisbabayan.com/api'
  : 'https://admin.sargisbabayan.com/api'

export const getDynamicData = async (endpoint, setData, setAdditionalData, language='en') => {
  try {
    const response = await axios.get(`${baseApiUrl}/${endpoint}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-localization': language,
      }
    })
    const dataRes = response.data
    if (dataRes) {
      setData(dataRes.data)
      if (setAdditionalData && dataRes.links) {
        setAdditionalData(dataRes.links)
      }
    }
  } catch (error) {
    toast.error('Please try again.')
    console.error('Error:', error)
  }
}

export const fetchDataPaginationBase = async (page, setData, setAdditionalData, token, pageName) => {
  try {
    const response = await fetch(`${baseApiUrl}/${pageName}?page=${page}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    const dataRes = await response.json()
    if (dataRes) {
      setData(dataRes.data)
      setAdditionalData(dataRes.links)
    }
  } catch (error) {
    toast.error('Failed to load data. Please try again.')
    console.error('Error:', error)
  }
}
