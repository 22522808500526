import './App.css';
import Header from './components/Header';
import Footer from "./components/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import Home from "./pages/Home";
import Exhibitions from "./pages/Exhibitions";
import About from "./pages/About";
import Works from "./pages/Works";
import Gallery from "./pages/Gallery";
import {ThemeProvider} from "./providers/themeProvider";
import Contacts from "./pages/Contacts";
import Work from "./pages/Work";
import Exhibition from "./pages/Exhibition";
import { Provider } from 'react-redux';
import store from "./redux/store";


function App() {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <div className="max-w-[1680px] m-auto w-full bg-lightBg dark:bg-darkBg">
          <Router>
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/exhibition" element={<Exhibitions />} />
              <Route path="/exhibition/:id" element={<Exhibition />} />
              <Route path="/about" element={<About />} />
              <Route path="/works" element={<Works />} />
              <Route path="/works/:id" element={<Work />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/contacts" element={<Contacts />} />
            </Routes>
            <Footer />
            <Toaster />
          </Router>
        </div>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
